.form-upload-container {
  width: 100%;
}

.preview-image {
  max-width: 100%;
}

.full-width-picker {
  width: 100%;
}

.margin-top-button {
  margin-top: 16px;
}

.margin-right {
  margin-right: 16px;
}

/* 用于文本区域的样式 */
.formatted-text-display {
  white-space: pre-wrap; /* 保留换行和空格 */
  word-wrap: break-word; /* 确保长文本自动换行 */
  font-family: SimSun, "宋体", "Times New Roman", Times, serif; /* 与全局字体一致 */
  line-height: 1.5; /* 设置行高 */
  padding: 8px 0; /* 添加一些内边距 */
}

/* 语言能力和教育背景表单TextArea的样式 */
.ant-form-item .ant-input[type="textarea"] {
  white-space: pre-wrap;
  font-family: SimSun, "宋体", "Times New Roman", Times, serif;
}

/* 语言能力展示区域的样式 */
.language-ability-display {
  margin-bottom: 20px;
}

.language-section {
  margin-bottom: 15px;
}

.language-section h4 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #666;
}

.language-section .formatted-text-display {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 8px 12px;
  margin-top: 4px;
  border-left: 3px solid #1890ff;
} 